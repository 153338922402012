html {
  box-sizing: border-box;
  @include font-regular;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-overflow-scrolling: touch;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  // font-size: px-to-rem(16);
  background-color: var(--main-black);
  &.rotate-screen-visible {
    background-color: $rotate-screen-bg-color !important; // trick to avoid the iPhone X notch
  }
  @include media('>=large') {
    overflow: hidden;
    &.changePage {
      cursor: wait;
    }
  }
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.only-aria-visible {
  clip-path: inset(100%);
  clip: rect(1px 1px 1px 1px); /* IE 6/7 */
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap; /* added line */
  width: 1px;
}

a {
  color: inherit;
}

ul,
dl,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

li,
dt,
dd {
  margin: 0;
  padding: 0;
}

button {
  cursor: pointer;
}
