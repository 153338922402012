@import 'shared.scss';

#root {
  //smooth scroll
  display: block;
  position: absolute !important;
  width: 100%;

  .PageOverlay {
    @include z-index(page-overlay);
  }

  .MainTopNav {
    opacity: 0;
    @include z-index(main-nav);
    padding: 2rem $navbar-padding-mobile;
    height: $navbar-height-mobile;
    background-color: rgba($black, 0);

    @include media('>=medium') {
      padding: var(--space-md) var(--space-md);
    }
    @include media('>=large') {
      padding: var(--space-md) var(--space-md);
    }

    .nav .nav-logo {
      opacity: 0;
      width: px-to-rem(60);
    }

    .nav .nav-item {
      a {
        font-size: var(--text-md);
        color: var(--main-white);
      }
    }
  }

  .HamburgerMenu {
    top: $navbar-height-mobile;
    @include z-index(sidebar);
    height: calc(100% - #{$navbar-height-mobile});
    border-left: none;
    background-color: transparent;

    .nav-item a {
      color: var(--main-white);
    }

    @include media('>=medium') {
      max-width: px-to-rem(300);
      border-left: 1px solid rgba($black, 0.3);
    }
  }

  .HamburgerButton .bar {
    background: var(--main-white);
  }

  .Footer {
    opacity: 0;
    @include z-index(foter);
    padding: 2rem $navbar-padding-mobile;
    display: none;
    position: relative;
    flex-direction: column;
    align-items: center;
    background-color: var(--main-black);
    color: var(--main-white);

    @include media('>=medium') {
      padding: var(--space-lg);
    }

    @include media('>=large') {
      padding: var(--space-lg) var(--space-md) var(--space-md);
      position: absolute;
      bottom: 0;
    }

    .footer-nav {
      color: var(--main-white);
      .nav-list {
        a {
          color: var(--main-white);
          font-size: var(--text-xs);
        }
      }
    }
    .footer-copyright {
      color: var(--main-white);
      font-size: var(--text-xs);
    }
  }

  .RotateScreen {
    @include z-index(rotate);
  }
}

.vs-scrollbar {
  z-index: 100;
}

.cookieConsent {
  background: $black;
  color: $white;
  position: fixed;
  text-align: center;
  padding: var(--space-md);
  bottom: px-to-rem(10) !important;
  right: px-to-rem(10);
  height: px-to-rem(145);
  width: 94%;
  z-index: 200;
  @include media('>=medium') {
    width: px-to-rem(340);
    height: px-to-rem(150);
  }
  @include media('>=large') {
    width: px-to-rem(400);
    height: px-to-rem(170);
    bottom: px-to-rem(20) !important;
    right: px-to-rem(20);
  }
  > div {
    font-size: calc(var(--text-sm) * 1.2);
    margin-bottom: px-to-rem(20);
    @include media('>=large') {
      font-size: var(--text-sm);
    }
  }
  button {
    color: $black;
    padding: px-to-rem(10) px-to-rem(20);
    background-color: $white;
    text-transform: uppercase;
    font-size: var(--text-xs);
    font-weight: 700 !important;
    transition: background-color 0.3s ease;
    &:hover {
      background-color: darken($white, 8%);
      transition: background-color 0.3s ease;
    }
  }
}
